<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('administration.account_setting.system_pages.ext_contacts')"
            label-for="top_extension_id"
            :state="errors && errors.contacts_extension_id ? false : null"
          >
            <v-select
              id="top_extension_id"
              v-model="data.contacts_extension_id.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="extensionOptions"
              :reduce="val => val.value"
              input-id="top_extension_id"
              :searchable="true"
              @search="searchExtension"
            />
            <b-form-invalid-feedback v-if="errors && errors.contacts_extension_id">
              {{ errors.contacts_extension_id[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('administration.account_setting.system_pages.ext_lk')"
            label-for="bottom_extension_id"
            :state="errors && errors.lk_extension_id ? false : null"
          >
            <v-select
              id="bottom_extension_id"
              v-model="data.lk_extension_id.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="extensionOptions"
              :reduce="val => val.value"
              input-id="bottom_extension_id"
              :searchable="true"
              @search="searchExtension"
            />
            <b-form-invalid-feedback v-if="errors && errors.lk_extension_id">
              {{ errors.lk_extension_id[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, vSelect, BButton, BFormInvalidFeedback, BOverlay,
  },
  data() {
    return {
      data: null,
      contacts_extension_id: null,
      lk_extension_id: null,
      extensionOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/administration/extensions')
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http('/api/administration/settings', { params: { group: 'syspages' } }).then(response => {
      const contactsExtensionId = parseInt(response.data.contacts_extension_id, 10)
      const lkExtensionId = parseInt(response.data.lk_extension_id, 10)

      /* eslint-disable camelcase */
      this.data = {
        contacts_extension_id: {
          label: contactsExtensionId !== 0 ? _.find(this.extensionOptions, { value: contactsExtensionId }).label : '---',
          value: contactsExtensionId,
        },
        lk_extension_id: {
          label: lkExtensionId !== 0 ? _.find(this.extensionOptions, { value: lkExtensionId }).label : '---',
          value: lkExtensionId,
        },
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async submit() {
      await this.$http.put('/api/administration/settings/syspages', {
        contacts_extension_id: this.data.contacts_extension_id ? this.data.contacts_extension_id.value : 0,
        lk_extension_id: this.data.lk_extension_id ? this.data.lk_extension_id.value : 0,
      })
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
