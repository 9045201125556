<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>

          <b-button
            variant="warning"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="cronUpdate"
          >
            Update Feeds & Sitemaps
          </b-button>

          <b-button
            variant="danger"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="clearCache"
          >
            Clear Cache
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, vSelect,
  },
  mixins: [StatusMixin, SweetAlertMixin],
  data() {
    return {
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'general' } }).then(response => {
      const { data: { maintenance_mode } } = response

      this.data = {
        maintenance_mode,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.$http.post('/api/administration/settings/cache/clear')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Background task started! Waiting +- 2min',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
      })
    },
    cronUpdate() {
      this.confirm(() => {
        this.$http.post('/api/administration/settings/cron/update')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Background task started! Waiting +- 10min',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Фіди занадто великі. Тому, їх формування буде продовжено у фоновому режимі ще певний період часу (+-10хв).',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Оновлювати їх можна не частіше 5 разів на добу, щоб не перегружати систему.',
        text: 'Продовжити?',
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
