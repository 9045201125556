<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.status.label')"
            label-for="river_status"
            :state="errors && errors.river_status ? false : null"
          >
            <v-select
              id="river_status"
              v-model="data.river_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="river_status"
            />
            <b-form-invalid-feedback v-if="errors && errors.river_status">
              {{ errors.river_status[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.stocks.label')"
            label-for="river_stock"
            :state="errors && errors.river_stock ? false : null"
          >
            <v-select
              id="river_stock"
              v-model="data.river_stock"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stocksOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="river_stock"
            />
            <b-form-invalid-feedback v-if="errors && errors.river_stock">
              {{ errors.river_stock[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            :label="'API'"
            label-for="river_api"
          >
            <b-form-input
              id="river_api"
              v-model="data.river_api"
              :state="errors && errors.river_api ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.river_api">
              {{ errors.river_api[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            :label="'Merchant UUID'"
            label-for="river_merchant_uuid"
          >
            <b-form-input
              id="river_merchant_uuid"
              v-model="data.river_merchant_uuid"
              :state="errors && errors.river_merchant_uuid ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.river_merchant_uuid">
              {{ errors.river_merchant_uuid[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            :label="'Merchant ID'"
            label-for="river_merchant_id"
          >
            <b-form-input
              id="river_merchant_id"
              v-model="data.river_merchant_id"
              :state="errors && errors.river_merchant_id ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.river_merchant_id">
              {{ errors.river_merchant_id[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">

          <div class="repeater-form">
            <b-row
              v-for="(category, index) in data.river_categories"
              :id="'category' + index"
              :key="index"
              ref="row"
            >
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  :label="$t('form.categories.label')"
                  :label-for="'categories.' + index + '.category_id'"
                  :state="errors && errors['categories.' + index + '.id'] ? false : null"
                >
                  <treeselect
                    :id="'categories.' + index + '.category_id'"
                    v-model="category.category_id"
                    :multiple="false"
                    :options="categoryOptions"
                    :normalizer="normalizer"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['categories.' + index + '.category_id']">
                    {{ errors['categories.' + index + '.category_id'][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  :label="$t('form.quantity.label')"
                  :label-for="'categories.' + index + '.quantity'"
                >
                  <b-form-input
                    :id="'categories.' + index + '.quantity'"
                    v-model="category.quantity"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors && errors['categories.' + index + '.quantity'] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['categories.' + index + '.quantity']">
                    {{ errors['categories.' + index + '.quantity'][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Remove Button -->
              <b-col
                md="2"
                class="mb-50"
              >
                <b-button
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  block
                  @click="removeCategory(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>

          <b-button
            variant="primary"
            @click="addCategory"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('general.add') }}</span>
          </b-button>

          <b-row>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="merchant"
        class="mt-2"
      >
        <b-col
          v-for="(m, index) in merchant"
          :key="`mm_${index}`"
          cols="12"
        >
          <p>{{ m }}</p>
        </b-col>
      </b-row>
      <b-row
        v-if="brands"
        class="mt-2"
      >
        <b-col
          v-for="(br, index) in brands"
          :key="`bb_${index}`"
          cols="12"
        >
          <h5>{{ index }}</h5>
          <p
            v-for="(b, i) in br"
            :key="`bbb_${i}`"
            v-text="b"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>

          <b-button
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="getMerchant"
          >
            Get RM Merchant
          </b-button>

          <b-button
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="brandsList"
          >
            Get brandsList
          </b-button>

          <b-button
            variant="info"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="synchProducts"
          >
            Synch RM Products
          </b-button>

          <b-button
            variant="warning"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateProducts"
          >
            Update RM Products
          </b-button>

          <b-button
            variant="warning"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadPrices"
          >
            Upload RM Prices
          </b-button>

          <b-button
            variant="danger"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="deleteProducts"
          >
            Delete RM Products
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'

export default {
  components: {
    Treeselect, vSelect, BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
  },
  data() {
    return {
      data: null,
      merchant: null,
      brands: null,
      categoryOptions: [],
      stocksOptions: [],
      statusOptions: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  mixins: [CategoryMixin],
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'river' } }).then(response => {
      this.data = response.data
    })

    this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    if (!Object.keys(this.categoryOptions).length) {
      await this.$http.get('/api/administration/categories', {
        params: {
          type: 'product',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryOptions = response.data
        })
    }
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/river', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    addCategory() {
      this.data.river_categories.push({
        category_id: null,
        quantity: 1,
      })
    },
    removeCategory(index) {
      this.data.river_categories.splice(index, 1)
    },
    async getMerchant() {
      await this.$http.post('/api/administration/settings/river/merchant')
        .then(async response => {
          this.merchant = response.data?.data?.result ?? response.data
        })
    },
    async synchProducts() {
      await this.$http.post('/api/administration/settings/river/synch')
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async brandsList() {
      await this.$http.post('/api/administration/settings/river/brands_list')
        .then(async response => {
          this.brands = response.data?.data ?? response.data
        })
    },
    async updateProducts() {
      await this.$http.post('/api/administration/settings/river/update')
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async uploadPrices() {
      await this.$http.post('/api/administration/settings/river/prices')
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async deleteProducts() {
      await this.$http.post('/api/administration/settings/river/delete')
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
